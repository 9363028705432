import React from 'react';
import {inject, observer} from 'mobx-react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Hidden from '@material-ui/core/Hidden';

import {LogoWithTextRuby} from '@zegal/components/src/components/icons';
import Search from '@zegal/portal/src/modules/search';
import TopBarItems, {SmallScreen as TopBarItemsSmScreen} from '@zegal/portal/src/modules/layout/topBarItems';

interface Props {
	window?: () => Window;
	children: React.ReactElement;
}

function ElevationScroll(props: Props) {
	const {children, window} = props;

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window ? window() : undefined
	});

	return React.cloneElement(children, {
		elevation: trigger ? 1 : 0,
		style: {
			backgroundColor: '#fff',
			borderBottom: trigger ? 'none' : '1px solid #e5e5e5'
		}
	});
}

const ToolBarLgScreen = (props) => {
	return (
		<ElevationScroll {...props}>
			<AppBar position='fixed' color='default'>
				<Toolbar variant='regular'>
					<Box display='flex' alignItems='center' width='1'>
						<Box width={{xs: 'auto', md: 150}}>
							<LogoWithTextRuby height='48px' />
						</Box>
						<TopBarItems />
					</Box>
				</Toolbar>
			</AppBar>
		</ElevationScroll>
	);
};

interface ISmToolbar {
	children?: React.ReactNode;
	bgcolor?: string;
	color?: string;
}

export const SmToolbar = ({children, bgcolor = '#fff', color}: ISmToolbar) => {
	return (
		<Box
			display='flex'
			alignItems='center'
			justifyContent='space-between'
			width='1'
			height={{xs: 42, sm: 56}}
			color={color}
			bgcolor={bgcolor}
		>
			{children}
		</Box>
	);
};

const ToolBarSmScreen = () => {
	return (
		<>
			<AppBar position='fixed' color='default' elevation={0}>
				<SmToolbar>
					<Box
						px={0.5}
						width='1'
						color='#fff'
						height='1'
						display='flex'
						justifyContent='space-between'
						alignItems='center'
					>
						<LogoWithTextRuby height='94%' />

						<Search />
					</Box>
				</SmToolbar>

				<SmToolbar>
					<TopBarItemsSmScreen />
				</SmToolbar>
			</AppBar>
		</>
	);
};

class TopBar extends React.Component<any, any> {
	render() {
		const {menu} = this.props;

		if (!menu.appMenuBar) {
			return null;
		}

		return (
			<>
				<Hidden smDown>
					<ToolBarLgScreen {...this.props} />
				</Hidden>

				<Hidden mdUp>
					<ToolBarSmScreen />
				</Hidden>
			</>
		);
	}
}

export default inject('general', 'menu')(observer(TopBar));
