import React from 'react';

import RawTypography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import {makeStyles} from '@material-ui/core/styles';

import IconWrapper from './wrappers/icon';
import Wrapper from './wrappers/wrapper';
import addEvent from '@zegal/components/src/base/common/utils/addEvent';
import Tooltip from '@zegal/components/src/components/tooltips/tooltip';
import {pxToRem} from './assets/global.style';

const useStyles = makeStyles((theme) => ({
	tiny: {
		fontSize: pxToRem(11)
	},
	semiBold: {
		fontWeight: theme.typography.fontWeightMedium
	}
}));

// make the typography also log any onclick's to the event server
interface TypographyProps {
	onClick?: (a: any) => void;
	children: any;
	[x: string]: any;
}
const Typography: React.SFC<TypographyProps> = ({onClick, children, ...rest}) => {
	return (
		<RawTypography
			onClick={addEvent({
				onClick,
				title: rest.title,
				button: rest.identifier || rest.id
			})}
			{...rest}
		>
			{children}
		</RawTypography>
	);
};

export const Title = ({children, ...rest}) => (
	// @ts-ignore
	<Typography variant='h3' {...rest}>
		{children}
	</Typography>
);

export const Title2 = ({children, ...rest}) => (
	// @ts-ignore
	<Typography {...rest} variant='h1'>
		{children}
	</Typography>
);

export const Header = ({children, ...rest}) => (
	// @ts-ignore
	<Typography variant='subtitle2' paragraph component={Wrapper} {...rest}>
		{children}
	</Typography>
);

export const Header2 = ({children, ...rest}) => (
	// @ts-ignore
	<Typography variant='subtitle1' paragraph {...rest}>
		{children}
	</Typography>
);

export const HeaderIcon = ({icon, children, ...rest}) => (
	// @ts-ignore
	<TextWithIcon icon={icon} color='inherit' style={{marginBottom: 12}} {...rest}>
		<Header color='inherit' style={{margin: 0}}>
			{children}
		</Header>
	</TextWithIcon>
);

interface HeaderRequiredProps {
	children: any;
	variant?: string;
	disabled?: boolean;
	required?: boolean;
	noBold?: boolean;
	style?: any;
}
const HeaderRequired: React.SFC<HeaderRequiredProps> = ({children, variant, disabled, required, ...rest}) => (
	// @ts-ignore
	<Typography
		variant={variant || 'subtitle2'}
		color={disabled ? 'textSecondary' : 'textPrimary'}
		component='span'
		{...rest}
	>
		<Wrapper alignItems='center'>
			{children}

			{required && (
				// @ts-ignore
				<Wrapper display='inline-flex'>
					<Typography
						variant={variant || 'subtitle2'}
						color={disabled ? 'textSecondary' : 'error'}
						component='span'
					>
						&nbsp;*
					</Typography>
				</Wrapper>
			)}
		</Wrapper>
	</Typography>
);
export {HeaderRequired};

interface SmallProps {
	children: any;
	bold?: boolean;
	color?: string;
	alignItems?: any;
	component?: any;
	[x: string]: any;
}
const Small: React.SFC<SmallProps> = ({children, bold, color, alignItems, component, ...rest}) => (
	<Typography variant='caption' color={color || 'inherit'} component={component || Wrapper} {...rest}>
		<Wrapper alignItems={alignItems} component={bold ? 'b' : 'span'}>
			{children}
		</Wrapper>
	</Typography>
);
export {Small};

export const Caption = ({children, ...rest}) => (
	// @ts-ignore
	<Small color='textSecondary' component='div' {...rest}>
		{children}
	</Small>
);

export const Error = ({children, ...rest}) => (
	// @ts-ignore
	<Small color='error' {...rest}>
		{children}
	</Small>
);

export const Normal = ({children, ...rest}) => (
	// @ts-ignore
	<Typography variant='body1' {...rest}>
		{children}
	</Typography>
);

export const SemiBoldText = ({children, ...rest}) => {
	const classes = useStyles();
	return (
		<Typography className={classes.semiBold} {...rest}>
			{children}
		</Typography>
	);
};

export const TextWithCursor = ({children, ...rest}) => (
	<Wrapper enablePointer>
		<Small {...rest}>{children}</Small>
	</Wrapper>
);

export const Bold = ({children, ...rest}) => (
	// @ts-ignore
	<Typography component='b' {...rest}>
		{children}
	</Typography>
);

export const Primary = ({children, ...rest}) => (
	// @ts-ignore
	<Typography color='primary' component={Wrapper} {...rest}>
		{children}
	</Typography>
);

export const Secondary = ({children, ...rest}) => (
	// @ts-ignore
	<Typography color='textSecondary' component={Wrapper} {...rest}>
		{children}
	</Typography>
);

export const Tiny = ({children, ...rest}) => {
	// @ts-ignore
	const classes = useStyles();
	return (
		<Typography className={classes.tiny} {...rest}>
			{children}
		</Typography>
	);
};

export const SecondarySimple: React.SFC<{children: any}> = ({children}) => (
	<Typography color='textSecondary'>{children}</Typography>
);

interface TextWithIconProps {
	icon: string | React.ReactElement;
	iconSize?: string | boolean;
	iconMargin?: string;
	color?: string | boolean;
	children?: any;
	text?: string;
	textVariant?: string;
	[x: string]: any;
}
export const TextWithIcon: React.SFC<TextWithIconProps> = ({
	icon,
	iconSize,
	iconMargin = 'only-right',
	color,
	children,
	text,
	textVariant,
	...rest
}) => (
	<Wrapper alignItems='center' {...rest}>
		{icon && (
			<IconWrapper margin={iconMargin} size={iconSize || false} color={color || false}>
				{icon}
			</IconWrapper>
		)}

		{children ? (
			children
		) : (
			// @ts-ignore
			<Typography
				aria-label={text}
				variant={textVariant || 'body1'}
				color={color || 'textPrimary'}
				component='span'
			>
				{text}
			</Typography>
		)}
	</Wrapper>
);

// Media typography
// showing for large screen
export const TextSmDown = (props) => (
	<Hidden smDown>
		<Normal {...props} />
	</Hidden>
);

// showing for small screen
export const TextMdUp = (props) => (
	<Hidden mdUp>
		<Normal {...props} />
	</Hidden>
);
interface EllipsisText {
	label: any;
	variant: string;
	[x: string]: any;
}

export const TooltipEllipsisText: React.FC<EllipsisText> = ({label, variant = 'body2', ...rest}) => {
	return (
		<Tooltip content={label}>
			<Typography noWrap variant={variant} {...rest}>
				{label}
			</Typography>
		</Tooltip>
	);
};
