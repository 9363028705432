import React from 'react';
import Input from '@material-ui/core/Input';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import App from '@zegal/portal/src/app';
import LoadingButton from '@zegal/components/src/components/buttons/loading';
import {getErrorType, enumErrorType} from '@zegal/portal/src/entities/utils';

const isEmpty = (data) => {
	return Object.keys(data).every((k) => {
		return !data[k];
	});
};

export default function Create({closeModal}) {
	const [integration, setValues] = React.useState({
		app: '',
		displayName: ''
	});

	return (
		<Box display='grid' mb={1} gridTemplateRows='auto 1fr'>
			<Typography variant='h6' color='primary' paragraph>
				Create new integration key
			</Typography>

			<form noValidate autoComplete='off'>
				<Box component='section' mt={1} mb={6}>
					<Box mb={4}>
						<Typography paragraph variant='subtitle1' htmlFor='app' component='label'>
							API Name
						</Typography>

						<Input
							id='app'
							autoFocus
							value={integration.app}
							type='text'
							fullWidth
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setValues({...integration, ...{app: event.target.value}});
							}}
						/>
					</Box>

					<Box mb={6}>
						<Typography paragraph variant='subtitle1' htmlFor='displayName' component='label'>
							API Display Name
						</Typography>

						<Input
							id='displayName'
							value={integration.displayName}
							type='text'
							fullWidth
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setValues({...integration, ...{displayName: event.target.value}});
							}}
						/>
					</Box>
				</Box>
			</form>

			<Grid container spacing={2} alignItems='center'>
				<Grid item xs={12} md={9}>
					<Typography>On the next step, you will be able to set access and webhooks</Typography>
				</Grid>

				<Grid item xs={12} md={3}>
					<LoadingButton
						id='save-new-api'
						fullWidth
						disabled={!(integration.app && integration.displayName)}
						onClick={(event, btnId) => {
							if (isEmpty(integration)) {
								App.stores.general.disableButtonLoading(btnId);
								return App.actions.message({
									message: 'Inputs cannot be empty!',
									info: true,
									long: true
								});
							}

							App.stores.entities
								.create(integration)
								.then((model) => {
									closeModal();
									App.history.push(`/integrations/${model._id}/details`);
								})
								.catch((error) => {
									const errorType = getErrorType(error);

									if (errorType === enumErrorType.credit) {
										closeModal();
									}
								})
								.finally(() => {
									App.stores.general.disableButtonLoading(btnId);
								});
						}}
						color='secondary'
					>
						Save
					</LoadingButton>
				</Grid>
			</Grid>
		</Box>
	);
}
