export default (theme) => ({
	avatar: {
		width: theme.typography.pxToRem(35),
		height: theme.typography.pxToRem(35),
		marginRight: theme.typography.pxToRem(10),
		fontSize: theme.typography.pxToRem(15),
		letterSpacing: theme.typography.pxToRem(1),
		overflow: 'visible',
		display: 'flex',
		alignSelf: 'flex-start',

		'& img': {
			width: '100%',
			height: '100%',
			borderRadius: '100%',
			objectFit: 'cover'
		}
	},

	nomargin: {
		marginRight: '0 !important',
	},

	overlay: {
		marginRight: `${theme.typography.pxToRem(-8)} !important`,
		border: `${theme.typography.pxToRem(2)} solid` + theme.palette.background.paper,
		boxShadow: 'none',

		'&:hover': {
			marginTop: `${theme.typography.pxToRem(-4)}`,
			zIndex: 1,
			transition: `margin-top ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`
		}
	},
	disableHoverEffect: {
		'&:hover': {
			marginTop: 0,
			zIndex: 1,
		}
	},

	xs: {
		width: theme.typography.pxToRem(25),
		height: theme.typography.pxToRem(25),
		fontSize: theme.typography.pxToRem(12),
		alignSelf: 'unset'
	},

	sm: {
		width: theme.typography.pxToRem(25),
		height: theme.typography.pxToRem(25),
		fontSize: theme.typography.pxToRem(12),
	},

	md: {
		width: theme.typography.pxToRem(50),
		height: theme.typography.pxToRem(50)
	},

	lg: {
		width: theme.typography.pxToRem(108),
		height: theme.typography.pxToRem(108),
		fontSize: theme.typography.h5.fontSize
	},

	colorDefault: {
		backgroundColor: theme.color.default.error
	}
});
