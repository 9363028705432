export default (theme) => ({
	popper: {
		opacity: 1,
		zIndex: theme.zIndex.drawer,

		'& *:empty': {
			display: 'none'
		},

		'& mark': {
			backgroundColor: 'transparent',
			color: theme.palette.primary.main
		}
	},

	tooltip: {
		maxWidth: theme.typography.pxToRem(240),
		padding: theme.typography.pxToRem(6),
		borderRadius: theme.typography.pxToRem(3),
		backgroundColor: theme.palette.primary.dark,
		boxShadow: `0 ${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(8)} rgba(0, 0, 0, .2)`,
		fontSize: theme.typography.pxToRem(11),
		color: '#fff',
		wordBreak: 'break-word',
		fontWeight: 400,

		'& b': {
			fontWeight: 800
		},

		'& .tooltip-arrow': {
			display: 'block !important',
			color: theme.palette.primary.dark,
		},

		'& mark': {
			backgroundColor: theme.palette.primary.light,
			color: theme.palette.text.main
		}
	},

	// color
	white: {
		backgroundColor: '#fff',
		color: theme.palette.text.primary,

		'& .tooltip-arrow': {
			color: '#fff',
		}
	},

	secondary: {
		boxShadow: 'none',
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.primary,

		'& .tooltip-arrow': {
			color: theme.palette.background.default,
		}
	},

	// padding
	padding: {
		'&lg': {
			padding: `${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(20)}`,
			lineHeight: 1.6
		}
	}
});
