import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import App from '@zegal/portal/src/app';
import IconWrapper from '@zegal/components/src/components/wrappers/icon';
import Button from '@zegal/components/src/components/buttons/button';
import {openTab} from '@zegal/components/src/base/common/utils/misc';

export function GoToApp() {
	const onClick = () => {
		openTab(App.getConfig('AppRoot'));
	};

	return (
		<>
			<Hidden mdUp>
				<ListItem button onClick={onClick}>
					<ListItemIcon>
						<IconWrapper color='inherit' margin='none'>
							keyboard_arrow_left
						</IconWrapper>
					</ListItemIcon>

					<ListItemText
						primary='Go to App'
						primaryTypographyProps={{
							noWrap: true,
							variant: 'subtitle1'
						}}
					/>
				</ListItem>
			</Hidden>

			<Hidden smDown>
				<Button simple onClick={onClick} icon='keyboard_arrow_left'>
					Go to App
				</Button>
			</Hidden>
		</>
	);
}
