import React from 'react';
import ReactDOM from 'react-dom';
import get from 'lodash/get';
import includes from 'lodash/includes';
import {Provider, inject, observer} from 'mobx-react';
import {withRouter, BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';

import App from '@zegal/portal/src/app';
import WrapThemeProvider from '@zegal/components/src/base/common/wrapThemeProvider';
import config from '@zegal/portal/src/common/config/app';
import LayoutWrapper from '@zegal/portal/src/modules/layout/wrapper';
import {Routes, basePaths} from '@zegal/portal/src/routes';

const AuthWrapper = inject('general')(
	withRouter(
		observer(
			class AuthWrapper extends React.Component<any, any> {
				render() {
					const {general, children, history} = this.props;

					const pathname = window.location.pathname;
					const shouldProceedWithLogout =
						general.shouldLogout && !includes(['/login', '/logout', '/'], pathname);

					if (shouldProceedWithLogout) {
						history.push('/logout');
						return <div>Starting logout...</div>;
					}

					return children;
				}
			}
		)
	)
);

class AppWrapper extends React.Component<any, any> {
	constructor(props) {
		super(props);

		this.state = {
			appReady: false,
			ThemeProvider: null
		};
	}

	componentDidMount() {
		App.processes.afterStart = () => {
			App.stores.markets.fetch();

			App.actions.changeTitle(App.t('app.apiportal.title'), {manual: true});

			const ThemeProvider = WrapThemeProvider(get(App, 'stores.general.theme.themeDefault'));

			App.actions.setupModals(ThemeProvider);

			this.setState({
				appReady: true,
				ThemeProvider
			});
		};
	}

	render() {
		const {appReady, ThemeProvider} = this.state;

		if (!appReady) {
			return (
				<div className='spinner-wrapper'>
					<div className='spinner' />
				</div>
			);
		}

		return (
			<Provider {...App.stores}>
				<Router {...App.stores}>
					<ThemeProvider>
						<LayoutWrapper>
							<AuthWrapper>
								<Switch>
									{/* home */}
									<Route
										exact
										path='/'
										render={() => {
											return <Redirect to={basePaths.dashboard} />;
										}}
									/>

									{[...App.Auth.routes, ...Routes].map((route) => {
										return App.RouteBuilder(route);
									})}

									{/* if nothing matched */}
									<Route
										render={() => {
											return <Redirect to='/404' />;
										}}
									/>
								</Switch>
							</AuthWrapper>
						</LayoutWrapper>
					</ThemeProvider>
				</Router>
			</Provider>
		);
	}
}

function renderApp() {
	ReactDOM.render(React.createElement(AppWrapper, {stores: App.Stores}), document.getElementById('glb-content'));
}

const setupStores = () => {
	return Promise.all([
		import('./common/stores/general'),
		import('./common/stores/menu'),
		import('./common/stores/root')
	]).then(([General, Menu, Root]) => {
		// @ts-ignore
		App.stores.general = General.default.create();

		// @ts-ignore
		App.stores.menu = Menu.default.create();

		App.stores.store = Root.default().create({});

		App.actions.setupStores();
	});
};

App.start({config}, {renderApp}, setupStores);
