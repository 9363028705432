import {uniqueId} from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {inject, observer} from 'mobx-react';

import Wrapper from '@zegal/components/src/components/wrappers/wrapper';
import Button from '@zegal/components/src/components/buttons/button';
import Spinner from '@zegal/components/src/components/progress/circular';

/**
 * Usage example
 *
 * In the handler:
 *
 * 			model.save(null, {
 * 				success: () => {
 * ------->			this.props.general.disableButtonLoading(id)
 * 					App.actions.message('Saved', {good: true})
 * 				},
 * 				error: (model, info) => {
 * 					this.props.general.disableButtonLoading(id)
 * 					App.actions.message('Error: ' + _.get(info, 'responseJSON.error'), {bad: true})
 * 				},
 * 			})
 *
 * In render:
 *
 *			<Button
 * ------->		id={id}
 *				value='Create'
 *				onClick={handleSave}
 *			/>
 *
 * or:
 *
 *			<Button
 * 				id={id}
 *				onClick={handleSave}
 *			>
 *				Create
 *			</Button>
 *
 */
const LoadingButton = inject('general')(
	observer(
		class LoadingButton extends Component {
			static propTypes = {
				classes: PropTypes.string,
				id: PropTypes.string,
				onClick: PropTypes.func.isRequired,
				text: PropTypes.string,
				children: PropTypes.any
			};

			static defaultProps = {
				color: 'primary',
				id: uniqueId('loading-button')
			};

			enableButton = (e) => {
				// allow only one click at a time
				if (!this.props.general.buttons.get(this.props.id)) {
					this.props.general.enableButtonLoading(this.props.id);
					this.props.onClick(e, this.props.id);
				}
			};

			componentWillUnmount() {
				this.props.general.disableButtonLoading(this.props.id);
			}

			render() {
				const {
					id,
					testid,
					color,
					size,
					spinnerExtras,
					text,
					children,
					general,
					disabled,
					className,
					hideLabelOnLoading,
					...rest
				} = this.props;

				const hasSpinner = general.buttons.get(id) === true;

				const btnClasses = classNames({
					[className]: className,
					'has-spinner': hasSpinner
				});

				return (
					<Button
						{...rest}
						disabled={disabled && !hasSpinner}
						color={color || 'secondary'}
						id={id}
						testid={testid}
						size={size}
						className={btnClasses}
						onClick={this.enableButton}
					>
						<Wrapper alignItems='center'>
							{hasSpinner && <Spinner color='inherit' size='sm' extras={spinnerExtras} />}

							{!(hideLabelOnLoading && hasSpinner) && (
								<label>
									{text}
									{children}
								</label>
							)}
						</Wrapper>
					</Button>
				);
			}
		}
	)
);

export default LoadingButton;
