import React from 'react';
import omit from 'lodash/omit';
import get from 'lodash/get';
import {IAnyStateTreeNode, types} from 'mobx-state-tree';

import App from '@zegal/portal/src/app';
import {serverCall, setKey} from '@zegal/components/src/base/common/stores/_general';

import {IKeysModel} from './model';

export type IZegalResponse<T> = {
	message: string;
	results: Array<T>;
};

export const DefaultActions = (self) => ({
	serverCall: serverCall(self),
	setKey: setKey(self)
});

/**
 * Accepts string and take first & last 3 characters and obscure the remaining string.
 *
 * Example:
 * ```javascript
 * assert(obscure('pk_387438wrgbs890'), 'pk_**********890')
 * ```
 * @param {string} str
 * @returns
 */
export const obscure = (str: string) => {
	let head = str.slice(0, 3);
	let tail = str.slice(-3);
	let lengthToPad = str.length - (head.length + tail.length);

	return head + tail.padStart(lengthToPad, '*');
};

export enum enumErrorType {
	credit = 'no-credit',
	validation = 'validation-error',
	notInOrg = 'not-in-org'
}

export const getErrorType = (error) => {
	return get(error, 'data.err.error.errorType', '');
};

export const giveUserFriendlyErrorMessage = ({error, message}) => {
	// add any other message
	const errorType = getErrorType(error);
	let friendlyMessage = `<b>${App.t('app.apiportal.error.common', {message})}</b>`;

	if (errorType === enumErrorType.validation) {
		const serverValidationErrorMessage = get(error, 'data.err.message', '');
		let defaultValidationMessage = `<b>${App.t('app.apiportal.error.validation')}</b>`;

		if (serverValidationErrorMessage) {
			defaultValidationMessage += `<br /> <i>${serverValidationErrorMessage}</i>`;
		}

		friendlyMessage = defaultValidationMessage;
	}

	if (errorType === enumErrorType.notInOrg) {
		friendlyMessage = `<b>${App.t('app.apiportal.error.notInOrg')}</b>`;
	}

	App.actions.message({
		message: () => {
			return (
				<span
					dangerouslySetInnerHTML={{
						__html: friendlyMessage
					}}
				/>
			);
		},
		bad: true,
		long: true
	});
};

export interface Constraint<T> extends IAnyStateTreeNode {}

export function generateMetaModel(name: string) {
	return types
		.model(name, {
			_id: '',
			url: ''
		})
		.actions((self) => ({setKey: setKey(self)}));
}

export function removeId(item: IKeysModel): Partial<IKeysModel> {
	if (item._id === '') {
		return omit(item, '_id');
	} else {
		return item;
	}
}

export function appendZeroIfLessThan10(value) {
	if (value < 10) {
		value = '0' + value;
	}

	return value;
}
