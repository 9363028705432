import React from 'react';
import Box from '@material-ui/core/Box';

import App from '@zegal/portal/src/app';
import Modal from '@zegal/components/src/components/modals/modal';
import WrapThemeProvider from '@zegal/components/src/base/common/wrapThemeProvider';

export function CommonModal({children, size = 'md'}) {
	const Child = (props) => {
		return (
			<Box display={{xs: 'block', md: 'grid'}} py={{xs: 1.5, md: 4}} px={{xs: 1.5, md: 4.5}}>
				{React.Children.map(children, (child) =>
					React.cloneElement(child, {
						closeModal: props.handleClose
					})
				)}
			</Box>
		);
	};
	return (
		<Modal
			open
			size={size}
			collapsed
			showTopBorder
			showCloseButton
			handleOnClose={(event, reason, cb) => {
				if (cb) {
					cb();
				}
			}}
		>
			<Child />
		</Modal>
	);
}

type TModalLoader = {
	Component: any;
	useCommonModalLayout?: boolean;
	props?: any;
	size?: string;
	renderOn?: Element | null;
	options?: any;
};

export function modalLoader({
	Component,
	useCommonModalLayout = true,
	props,
	size,
	renderOn,
	options = {provide: true}
}: TModalLoader) {
	const ThemeWrapper = WrapThemeProvider(App.stores.general.theme.themeDefault);

	const WrapperComponent = useCommonModalLayout ? CommonModal : React.Fragment;

	return App.actions.loadComponent(
		() => (
			<ThemeWrapper>
				<WrapperComponent {...(useCommonModalLayout ? {size} : {})}>
					<Component {...props} />
				</WrapperComponent>
			</ThemeWrapper>
		),
		options,
		renderOn || document.getElementById('glb-modal')
	);
}
