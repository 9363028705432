import React from 'react';
import {observer} from 'mobx-react';
import Box from '@material-ui/core/Box';
import App from '@zegal/portal/src/app';
import ButtonBase from '@material-ui/core/ButtonBase';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconWrapper from '@zegal/components/src/components/wrappers/icon';

const useStyles = makeStyles(({palette, typography}) => ({
	root: {
		color: palette.secondary.contrastText,
		background: palette.secondary.main,
		padding: `0 ${typography.pxToRem(14)}`,
		borderRadius: typography.pxToRem(16),
		fontSize: typography.body1.fontSize,
		fontWeight: typography.fontWeightBold,
		display: 'flex',
		alignItems: 'center',
		height: typography.pxToRem(32),
		margin: `0 ${typography.pxToRem(8)}`,

		'& .icon': {
			fontSize: typography.pxToRem(18),
			margin: 0,
			marginRight: typography.pxToRem(3)
		}
	}
}));

export default observer(() => {
	const classes = useStyles();

	if (!App.stores.menu.upgrade) {
		return null;
	}

	return (
		<Box my={2}>
			<ButtonBase
				className={classes.root}
				onClick={() => {
					App.actions.showUpgradeBanner();
				}}
			>
				<IconWrapper className='icon' color='inherit'>
					lock
				</IconWrapper>
				Upgrade
			</ButtonBase>
		</Box>
	);
});
