export default (theme) => ({
	wrapper: {
		'& > *': {
			zIndex: theme.zIndex.tooltip
		}
	},

	popperWrapper: {
		width: 'max-content'
	},

	popper: {
		minWidth: theme.typography.pxToRem(160),
		width: 'auto',
		zIndex: theme.zIndex.tooltip,
		boxSizing: 'border-box',

		'&.lg': {
			width: theme.typography.pxToRem(600),
			marginTop: theme.typography.pxToRem(-3)
		}
	},

	popperOnClose: {
		pointerEvents: 'none'
	}
});
