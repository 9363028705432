import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress, {CircularProgressProps} from '@material-ui/core/CircularProgress';

import Wrapper from '../wrappers/wrapper';

interface IProps extends CircularProgressProps {
	center?: boolean;
	totalCenter?: boolean;
}

function Circular(props: IProps) {
	const {size, color, center, totalCenter, className} = props;

	let circumference = 24;

	if (size === 'lg') {
		circumference = 32;
	} else if (size === 'sm') {
		circumference = 16;
	}

	return (
		<Wrapper
			display='flex'
			position={totalCenter ? 'total-center' : 'relative'}
			justify={center ? 'center' : false}
		>
			<div
				data-testid='__ZEGAL__LOADING__SPINNER__'
				data-zegal-spinner='__ZEGAL__LOADING__SPINNER__'
				data-circumference={circumference}
			>
				<CircularProgress className={className} thickness={3} color={color} size={circumference} />
			</div>
		</Wrapper>
	);
}

Circular.propTypes = {
	size: PropTypes.oneOf(['sm', 'lg']),
	center: PropTypes.bool,
	totalCenter: PropTypes.bool,
	className: PropTypes.string,
	color: PropTypes.string
};

Circular.defaultProps = {
	color: 'primary'
};

export default Circular;
