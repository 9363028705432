import App from '@zegal/portal/src/app';

export enum basePaths {
	dashboard = '/dashboard',
	integrations = '/integrations'
}

export const Routes = [
	{
		path: basePaths.dashboard,
		options: {exact: true},
		component: App.loadFile({loader: () => import('@zegal/portal/src/modules/dashboard')}),
		public: false
	},
	{
		path: basePaths.integrations,
		options: {exact: true},
		component: App.loadFile({loader: () => import('@zegal/portal/src/modules/integrations/list')}),
		public: false
	},
	{
		path: [
			`${basePaths.integrations}/:id/details`,
			`${basePaths.integrations}/:id/access`,
			`${basePaths.integrations}/:id/webhooks`
		],
		options: {exact: true},
		component: App.loadFile({loader: () => import('@zegal/portal/src/modules/integrations/edit')})
	},
	{
		path: '/404',
		options: {exact: true},
		component: App.loadFile({loader: () => import('@zegal/portal/src/common/components/404')})
	}
];
