import React from 'react';
import {inject, observer} from 'mobx-react';
import {withRouter} from 'react-router';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';

import App from '@zegal/portal/src/app';

import TopBar, {SmToolbar} from './topBar';

class Layout extends React.Component<any, any> {
	componentDidMount() {
		App.history = this.props.history;
	}

	render() {
		const {general, children, history} = this.props;

		return general.isAuthenticated ? (
			<React.Fragment>
				<TopBar open={true} history={history} />

				{/* empty toolbar for spacing */}
				<Hidden smDown>
					<Toolbar />
				</Hidden>
				<Hidden mdUp>
					<SmToolbar />
					<SmToolbar />
				</Hidden>

				<Container maxWidth={false}>
					<Box py={{xs: 1.5, md: 2.8}} px={{xs: 0, md: 2.8}}>
						{children}
					</Box>
				</Container>
			</React.Fragment>
		) : (
			children
		);
	}
}

export default inject('general', 'menu')(withRouter(observer(Layout)));
