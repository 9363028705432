import React from 'react';
import Button from '@zegal/components/src/components/buttons/button';
import Hidden from '@material-ui/core/Hidden';

import {modalLoader} from '@zegal/portal/src/common/components/modal';

import Create from './create';

export const handleCreateModal = () => {
	const renderOn = document.getElementById('glb-extras');

	return modalLoader({
		Component: Create,
		size: 'sm',
		renderOn: renderOn || undefined
	});
};

const style = {
	padding: '4px 12px',
	minWidth: 'auto'
};

export default () => (
	<>
		<Hidden smDown>
			<Button style={style} border color='primary' icon='add' onClick={handleCreateModal}>
				Create
			</Button>
		</Hidden>

		<Hidden mdUp>
			<Button iconOutlined simple justIcon icon='add_box' color='primary' onClick={handleCreateModal} />
		</Hidden>
	</>
);
