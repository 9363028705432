import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {inject, observer} from 'mobx-react';

import Avatar from '@material-ui/core/Avatar';
import {withStyles} from '@material-ui/core/styles';

import App from '@zegal/components/src/base/base';
import IconWrapper from '@zegal/components/src/components/wrappers/icon';
import Tooltip from '@zegal/components/src/components/tooltips/tooltip';
import avatarStyle from '../assets/styles/avatar/base';

const avatarNotFoundPlaceHolder = 'avatar-not-found';

export const ACTOR_TYPES = {
	individual: 'individual',
	business: 'business'
};

const RegularAvatar = inject('orgs')(
	observer(
		/*
		 * Usage example
		 *
		 * <Avatar
		 *	person={object}
		 *	size='xs | sm | lg'
		 *	className='string'
		 * />
		 */

		class RegularAvatar extends React.Component {
			static defaultProps = {
				forceSearchAvatar: true
			};

			state = {
				personAvatar: '',
				personName: '',
				personDetails: {}
			};

			hasRendered = React.createRef();

			componentDidMount = () => {
				this.searchForAvatarImage(this.props.forceSearchAvatar);
			};

			get avatarNotFound() {
				return this.state.personAvatar === avatarNotFoundPlaceHolder;
			}

			waitAndFindAvatar = async (email) => {
				return new Promise((resolve) => {
					setTimeout(() => {
						const user = App.stores.contacts.findInContactsWithAvatars(email);
						resolve(user || {});
					}, 5000);
				});
			};

			searchForAvatarImage = async (force) => {
				const personDetails = this.props.person;
				let personAvatar = personDetails?.avatar || this.state.personAvatar;

				this.setState({
					personDetails
				});

				// trying to find avatar image from any possible reason
				if (!personAvatar) {
					let user = {};

					// attempt 1
					if (personDetails?.email === App.stores.user?.email) {
						user = App.stores.user;
					}

					if (isEmpty(user)) {
						// attempt 2
						user = App.stores.orgs.current.getPerson(personDetails?.email);

						if (isEmpty(user) || !user?.avatar) {
							// attempt 3
							user =
								App.stores.contacts.find(personDetails?.email) ||
								App.stores.contacts.findInContactsWithAvatars(personDetails?.email);

							// attempt 4
							if ((isEmpty(user) || !user?.avatar) && force && !this.avatarNotFound) {
								if (App.stores.contacts.isInSearchingQueue(personDetails?.email)) {
									user = await this.waitAndFindAvatar(personDetails?.email);
								} else {
									user = await App.stores.contacts.findContact(personDetails?.email, true);
								}
							}
						}
					}

					// if avatar image is found than merge it with personDetails
					personAvatar = user?.avatar || avatarNotFoundPlaceHolder;
				}

				this.setState({
					personAvatar,
					personName: personDetails?.primary_name?.full
				});
			};

			componentDidUpdate = (prevProps) => {
				if (!this.hasRendered?.current) {
					this.hasRendered.current = true;

					return;
				}

				const person = prevProps?.person;

				if (person?.email) {
					if (person?.primary_name?.full !== this.props.person?.primary_name?.full) {
						return this.searchForAvatarImage(this.props.forceSearchAvatar);
					}
				}
			};

			render() {
				const {
					classes,
					className,
					size,
					children,
					nomargin,
					overlay,
					disableHoverEffect,
					showTooltip,
					forceSearchAvatar,
					...rest
				} = this.props;

				const personDetails = this.state.personDetails;
				const personAvatar = this.props.person?.avatar || this.state.personAvatar;
				const isValidAvatar = personAvatar !== '' && personAvatar !== avatarNotFoundPlaceHolder;
				const status = personDetails?.taskStatus?.status;
				const fullName = personDetails?.primary_name?.full;
				const tooltipContent = status ? `${fullName} (${status})` : fullName;
				const showAvatarTooltip = showTooltip && tooltipContent;
				const AvatarWrapper = showAvatarTooltip ? Tooltip : React.Fragment;
				let wrapperProps = {};

				if (showAvatarTooltip) {
					wrapperProps = {
						...wrapperProps,
						content: tooltipContent,
						enterDelay: 1000,
						arrow: true
					};
				}

				const avatarClasses = classNames({
					[classes.avatar]: true,
					[classes[size]]: size,
					['avatar-color__' + personDetails?.colour]: personDetails?.colour,
					[className]: true,
					[classes.nomargin]: nomargin,
					[classes.overlay]: overlay,
					[classes.disableHoverEffect]: disableHoverEffect,
					'': forceSearchAvatar
				});

				return isValidAvatar ? (
					<AvatarWrapper {...wrapperProps}>
						<Avatar
							classes={{
								colorDefault: classes.colorDefault
							}}
							className={avatarClasses}
							{...rest}
						>
							<img src={personAvatar} alt={`avatar-${personDetails?.primary_name?.full}`} />
							{children}
						</Avatar>
					</AvatarWrapper>
				) : (
					<AvatarWrapper {...wrapperProps}>
						<Avatar
							classes={{
								colorDefault: classes.colorDefault
							}}
							className={avatarClasses}
							{...rest}
						>
							{personDetails?.type && personDetails?.type === ACTOR_TYPES.business ? (
								<IconWrapper color='inherit'>business</IconWrapper>
							) : (
								personDetails?.primary_name?.initials
							)}

							{children}
						</Avatar>
					</AvatarWrapper>
				);
			}
		}
	)
);

RegularAvatar.propTypes = {
	classes: PropTypes.object,
	size: PropTypes.oneOf(['xs', 'sm', 'lg', 'md', false]),
	person: PropTypes.any,
	forceSearchAvatar: PropTypes.bool
};

export default withStyles(avatarStyle)(RegularAvatar);
