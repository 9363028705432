import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import makeStyles from '@material-ui/core/styles/makeStyles';

import App from '@zegal/portal/src/app';
import Profile from '@zegal/portal/src/modules/profile';
import Notifications from '@zegal/portal/src/modules/notifications';
import Search from '@zegal/portal/src/modules/search';
import Create from '@zegal/portal/src/modules/integrations/create';
import IconButton from '@zegal/components/src/components/buttons/icon';

import Upgrade from './upgrade';
import {GoToApp} from './goToApp';

// TODO @pomba
// Make this less horrible.
const mapPathToIndex = () => {
	const current = window.location.pathname;

	if (current === '/dashboard') {
		return 0;
	}

	if (current === '/integrations' || current.match(/integrations\/+.*\/+.*/)) {
		return 1;
	}

	return 0;
};

const Modules = () => {
	const [current, setCurrent] = React.useState(mapPathToIndex());

	return (
		<>
			<Hidden smDown>
				<Tabs
					value={current}
					onChange={(_, next) => setCurrent(next)}
					aria-label='simple tabs example'
					textColor='primary'
					indicatorColor='secondary'
					variant='fullWidth'
				>
					<Tab label='Dashboard' onClick={() => App.history.push('/dashboard')} />
					<Tab label='My Integrations' onClick={() => App.history.push('/integrations')} />
				</Tabs>
			</Hidden>

			<Hidden mdUp>
				<IconButton
					color='primary'
					onClick={() => {
						setCurrent(0);
						App.history.push('/dashboard');
					}}
					icon='dashboard'
					iconOutlined={current !== 0}
					simple
				/>

				<IconButton
					color='primary'
					onClick={() => {
						setCurrent(1);
						App.history.push('/integrations');
					}}
					icon='build'
					iconOutlined={current !== 1}
					simple
				/>
			</Hidden>
		</>
	);
};

const smScreenWrapper = makeStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		height: '100%',

		'& > *': {
			flex: '1 1',
			height: 'inherit',
			padding: 0,
			margin: 0,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			borderRadius: 0
		}
	}
});

export const SmallScreen = () => {
	const classes = smScreenWrapper();

	return (
		<Box className={classes.root}>
			<Modules />

			<Create />

			{/* PHASE: 2 */ false && <Notifications />}

			<Box>
				<Profile />
			</Box>
		</Box>
	);
};

export default () => (
	<>
		<Upgrade />

		<Modules />

		<Box ml={4}>
			<Create />
		</Box>

		<Box flex='1 auto' justifyContent='flex-end' alignItems='center' display='flex'>
			{
				/* PHASE: 2 */ false && (
					<>
						<Search />
						<Box mr={2}>
							<Notifications />
						</Box>
					</>
				)
			}

			<Box ml={4}>
				<GoToApp />
			</Box>

			<Box ml={1}>
				<Profile />
			</Box>
		</Box>
	</>
);
