import React from 'react';
import Hidden from '@material-ui/core/Hidden';

import IconButton from '@zegal/components/src/components/buttons/icon';

export default () => {
	return (
		<>
			<Hidden smDown>
				<IconButton color='primary' icon='search' simple onClick={() => {}} />
			</Hidden>

			<Hidden mdUp>
				<IconButton color='primary' icon='search' simple onClick={() => {}} disableRipple />
			</Hidden>
		</>
	);
};
