/*
 *  Note:  This file should only consist icons which is tends
 *		  to be distributed multiple times in our different app.
 *		  First you need to place your icon inside assets/svgs
 *
 * Learn more:
 *	Adding SVGs in React: https://facebook.github.io/create-react-app/docs/adding-images-fonts-and-files#adding-svgs
 */

import React from 'react';

import {ReactComponent as Logo} from './assets/svgs/icon-dragonlaw.svg';
import {ReactComponent as LogoRuby} from './assets/svgs/icon-dragonlaw--ruby.svg';
import {ReactComponent as LogoWithText} from './assets/svgs/icon-logo-text.svg';
import {ReactComponent as LogoWithTextRuby} from './assets/svgs/icon-logo-text--ruby.svg';
import {ReactComponent as LogoWithTextGrey} from './assets/svgs/icon-logo-text--grey.svg';
import {ReactComponent as DeadDragon} from './assets/svgs/icon-error--mono.svg';

import {ReactComponent as AmericanExpress} from './assets/svgs/icon-cc-amex--color.svg';
import {ReactComponent as MasterCard} from './assets/svgs/icon-cc-mastercard--color.svg';
import {ReactComponent as Visa} from './assets/svgs/icon-cc-visa--color.svg';
import {ReactComponent as IconSecureSSL} from './assets/svgs/icon-secure-ssl.svg';
import {ReactComponent as IconZegalSign} from './assets/svgs/icon-zegal-sign.svg';
import {ReactComponent as IconUpgrade} from './assets/svgs/icon-upgrade.svg';
import {ReactComponent as IconBuyRoles} from './assets/svgs/icon-buy-single-roles.svg';
import {ReactComponent as IconThankyou} from './assets/svgs/icon-thank-you.svg';

import {ReactComponent as NotFound} from './assets/svgs/not-found.svg';
import {ReactComponent as Alert} from './assets/svgs/icon-alert--color.svg';

import {ReactComponent as MailExpired} from './assets/svgs/icon-mail--expired.svg';
import {ReactComponent as MailConfirmed} from './assets/svgs/icon-mail--confirmed.svg';
import {ReactComponent as MailColor} from './assets/svgs/icon-mail--color.svg';

import {ReactComponent as GoogleG} from './assets/svgs/icon-googleg.svg';
import {ReactComponent as Microsoft} from './assets/svgs/icon-microsoft.svg';
import {ReactComponent as FreeDoc} from './assets/svgs/icon-free-doc.svg';
import {ReactComponent as Scales} from './assets/svgs/icon-scales.svg';
import {ReactComponent as GoogleD} from './assets/svgs/google-drive.svg';
import {ReactComponent as MicrosoftD} from './assets/svgs/one-drive.svg';

import {ReactComponent as VectorDeleteFiles} from './assets/svgs/vector-delete-files.svg';

import {ReactComponent as Pdf} from './assets/svgs/icon-pdf.svg';
import {ReactComponent as Docx} from './assets/svgs/icon-docx.svg';
import {ReactComponent as IconUpload} from './assets/svgs/icon-upload.svg';

import flagAUS from './assets/pngs/AUS.png';
import flagGBEAW from './assets/pngs/GBEAW.png';
import flagHKG from './assets/pngs/HKG.png';
import flagNZL from './assets/pngs/NZL.png';
import flagSGP from './assets/pngs/SGP.png';
import flagMYS from './assets/pngs/MYS.png';
import flagUSA from './assets/pngs/USA.png';
import flagTWN from './assets/pngs/TWN.png';
import flagWLD from './assets/pngs/WLD.png';

import {ReactComponent as ZegalSign} from './assets/svgs/icon-zegal-sign--teal.svg';
import IconWrapper from './wrappers/icon';

export const makeLogo = (src) => {
	return imagify(src, 'logo', 56, 56 * 2.2, 'logo');
};

export const imagify = (src, alt, height, width, className) => {
	return (
		<img
			alt={alt}
			src={src}
			className={className}
			style={{
				height: '100%',
				minHeight: height,
				width
			}}
		/>
	);
};

export const Icon = (name, Component) => (
	<IconWrapper color='inherit'>
		{name}
		{Component}
	</IconWrapper>
);

const documentIcon = Icon('description');
const eventIcon = Icon('event');
const uploadIcon = Icon('', <ZegalSign />);
const decisionIcon = Icon('thumbs_up_down');
const checklistIcon = Icon('playlist_add_check');

const IconFlagAUS = imagify(flagAUS, 'flag-aus', 18, 24);
const IconFlagGBEAW = imagify(flagGBEAW, 'flag-gb-eaw', 18, 24);
const IconFlagGBR = IconFlagGBEAW;
const IconFlagHKG = imagify(flagHKG, 'flag-hkg', 18, 24);
const IconFlagNZL = imagify(flagNZL, 'flag-nzl', 18, 24);
const IconFlagSGP = imagify(flagSGP, 'flag-sgp', 18, 24);
const IconFlagMYS = imagify(flagMYS, 'flag-mys', 18, 24);
const IconFlagUSA = imagify(flagUSA, 'flag-usa', 18, 24);
const IconFlagTWN = imagify(flagTWN, 'flag-mys', 18, 24);
const IconFlagWLD = imagify(flagWLD, 'flag-wld', 18, 24);

const document = documentIcon;
const event = eventIcon;
const upload = uploadIcon;
const decision = decisionIcon;
const checklist = checklistIcon;

export {
	Logo,
	LogoRuby,
	LogoWithText,
	LogoWithTextRuby,
	LogoWithTextGrey,
	DeadDragon,
	AmericanExpress,
	MasterCard,
	Visa,
	IconSecureSSL,
	NotFound,
	Alert,
	MailExpired,
	MailConfirmed,
	MailColor,
	GoogleG,
	Microsoft,
	FreeDoc,
	Scales,
	ZegalSign,
	document,
	event,
	upload,
	decision,
	checklist,
	IconZegalSign,
	IconFlagAUS,
	IconFlagGBEAW,
	IconFlagGBR,
	IconFlagHKG,
	IconFlagNZL,
	IconFlagSGP,
	IconFlagMYS,
	IconFlagUSA,
	IconFlagTWN,
	IconFlagWLD,
	VectorDeleteFiles,
	Pdf,
	Docx,
	IconUpload,
	IconUpgrade,
	IconBuyRoles,
	IconThankyou,
	GoogleD,
	MicrosoftD
};
