import get from 'lodash/get';
import App from '@zegal/components/src/base/core';
import {getErrorType, enumErrorType, giveUserFriendlyErrorMessage} from '@zegal/portal/src/entities/utils';
import info from './info.json';

App.actions.setupStores = (regenerate = false) => {
	return import('./entities/setup').then(({setup}) => {
		const [entities, reports] = setup(regenerate);

		App.stores.entities = entities;

		App.stores.reports = reports;
	});
};

App.fetchI18N = (domain) => {
	if (domain) {
		return import('./common/scribe.domain.json');
	}

	return import('./common/scribe.json');
};

App.actions.checkCredit = async () => {
	try {
		const currentOrg = App.stores.orgs.current;
		await currentOrg.getAvailableCredits();
		const available = currentOrg.availableCredits.is('apiAccess_limit', 'available');
		App.stores.menu.setUpgrade(available === false);
	} catch (error) {
		App.actions.error(error);
		throw error;
	}
};

App.actions.setCurrentOrg = async (orgId) => {
	try {
		await App.stores.orgs.setCurrent(orgId);
		App.actions.checkCredit();
	} catch (error) {
		App.actions.error(error);
		throw error;
	}
};

App.actions.switchOrg = async (orgId, callback) => {
	try {
		await App.actions.setCurrentOrg(orgId);
		await App.stores.user.switchOrg(orgId);
		callback && callback();
	} catch (e) {
		App.log('app_error', {message: e.message, type: 'switching-org'});
		App.actions.error(e);
	}
};

App.actions.showUpgradeBanner = async () => {
	const org = App.stores.orgs.current;
	const userEmail = App.stores.user.email;
	const isOwnerOrAdmin = org.isAdmin(userEmail) || org.isBillingAdmin(userEmail);

	const response = await org.requestCreditMessage('apiAccess');

	const creditMessage = get(response, 'value') || response;

	import('@zegal/components/src/components/transactionModals/components/upgrade/banner').then((Banner) => {
		let props = {
			Component: Banner.default,
			org,
			size: creditMessage && !isOwnerOrAdmin ? 'sm' : 'md',
			creditMessage,
			color: 'secondary',
			showCloseButton: true,
			showTopBorder: true,
			collapsed: true,
			isOwnerOrAdmin,
			App
		};

		App.actions.showModal(
			{...props},
			{
				provide: ['orgs', 'user', 'store']
			}
		);
	});
};

App.actions.processError = (error, message) => {
	const errorType = getErrorType(error);

	if (error.status === 404) {
		if (App?.history?.push) {
			App.history.push('/404');
		}
	}

	if (errorType === enumErrorType.credit) {
		App.actions.showUpgradeBanner();
	} else {
		// default friendly message
		giveUserFriendlyErrorMessage({
			error,
			message
		});
	}

	App.actions.logError('portal_app_error', error, true);
	console.error('portal_app_error', error);
};

App.defaults = {
	version: info.version,

	seeDisclaimer: true,

	// the route to goto after login
	homepath: '/dashboard',
	disableExtraCaching: true,
	// APIPrefix: '/api/',

	platform: 'portal',

	APIDTEPrefix: '/api/'
};

App.modalRegion = {};

App.adminModules = [];

App.UnAuthRoutes = [];

App.authModules = [];

App.userModules = [];

App.processes.afterSetup = () => {
	if (App.getConfig('debug')) {
		// @ts-ignore
		window.app = App;
	}
};

App.processes.afterLogin = () => {
	try {
		// this fetches the org, and set's it as current on the store
		App.actions.setCurrentOrg(App.stores.user.primary_org_id);
	} catch (e) {
		App.log('app_error', {message: e.message, type: 'setting-org'});
		App.actions.error(e);
	}
};

App.processes.afterLogout = () => {
	// reset all stores
	App.actions.setupStores(true);
	App.setNextUrl(App.getConfig('homepath'));
	App?.sockets?.stopAll();
};

export default App;
