import React from 'react';
import {observer} from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Hidden from '@material-ui/core/Hidden';

import App from '@zegal/portal/src/app';
import IconWrapper from '@zegal/components/src/components/wrappers/icon';
import Avatar from '@zegal/components/src/components/avatars/avatar';
import Popper from '@zegal/components/src/components/popper/uncontrolled';
import {TextWithIcon} from '@zegal/components/src/components/typography';

import {GoToApp} from '@zegal/portal/src/modules/layout/goToApp';

const CommonAvatar = ({user = App.stores.user}) => {
	return (
		<Box display='flex' alignItems='center'>
			<Avatar person={user} />

			<Box overflow='hidden' textAlign='left' maxWidth='200px'>
				<Typography title={user.primary_name.full} color='inherit' variant='h6' noWrap>
					{user.primary_name.full}
				</Typography>

				<Typography title={user.email} color='textSecondary' variant='caption' noWrap>
					{user.email}
				</Typography>
			</Box>
		</Box>
	);
};

const AvatarWithPopper = () => {
	return (
		<Popper
			target={(onClick) => {
				return (
					<ButtonBase onClick={onClick}>
						<CommonAvatar />
					</ButtonBase>
				);
			}}
			content={() => (
				<List>
					<ListItem button onClick={() => App.history.push('/logout')}>
						<TextWithIcon icon='exit_to_app' color='primary' text='Log Out' textVariant='subtitle2' />
					</ListItem>
				</List>
			)}
		/>
	);
};

const AvatarWithDrawer = ({user = App.stores.user}) => {
	const [openDrawer, setOpenDrawer] = React.useState(false);

	return (
		<>
			<ButtonBase onClick={() => setOpenDrawer(true)}>
				<Avatar size='xs' nomargin person={user} />
			</ButtonBase>

			<Drawer anchor='left' open={openDrawer} onClose={() => setOpenDrawer(false)}>
				<Box width='280px' maxWidth='310px'>
					<List>
						<ListItem>
							<ListItemAvatar>
								<Avatar person={user} nomargin />
							</ListItemAvatar>

							<ListItemText
								primary={user.primary_name.full}
								primaryTypographyProps={{
									noWrap: true,
									variant: 'subtitle1'
								}}
								secondary={user.email}
								secondaryTypographyProps={{
									noWrap: true
								}}
							/>
						</ListItem>

						<GoToApp />

						<ListItem button onClick={() => App.history.push('/logout')}>
							<ListItemIcon>
								<IconWrapper color='primary' margin='none'>
									exit_to_app
								</IconWrapper>
							</ListItemIcon>

							<ListItemText
								primary='Log Out'
								primaryTypographyProps={{
									noWrap: true,
									variant: 'subtitle1'
								}}
							/>
						</ListItem>
					</List>
				</Box>
			</Drawer>
		</>
	);
};

const Profile = () => {
	return (
		<>
			<Hidden smDown>
				<AvatarWithPopper />
			</Hidden>

			<Hidden mdUp>
				<AvatarWithDrawer />
			</Hidden>
		</>
	);
};

export default observer(Profile);
